import {createSlice} from "@reduxjs/toolkit";
import {userApi} from "../../app/services/userApi";
import {RootState} from "../../app/store";
import {doesSessionExist} from 'supertokens-auth-react/recipe/session';

const DEFAULT_LOCALE = "ru"

const userSlice = createSlice({
    name: "user",
    initialState: {
        locale: DEFAULT_LOCALE,
    },
    reducers: {
        setLocale: (state, action) => {
            state.locale = action.payload;
        },
    },
});

export const {setLocale} = userSlice.actions;

export const fetchUserProfile = () => async (dispatch: any) => {
    const isLoggedIn = await doesSessionExist();
    if (!isLoggedIn) {
        return
    }
    const {data} = await dispatch(userApi.endpoints.getSelf.initiate())
    if(!data?.isActive) {
        window.location.href = "https://welcome.trisystems.ai"
    }
    dispatch(userSlice.actions.setLocale(data?.locale || DEFAULT_LOCALE));
};

export default userSlice.reducer;
export const selectLocale = (state: RootState) => state.user.locale;
